<template>
  <div class="page_content page_content_news">
    <div class="content_header d-flex align-items-center">
      <span>消息管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between">
          <div class="search_section flex-fill">
            <div class="form-group d-flex align-items-center">
              <label for="system">系統</label>
              <select
                id="system"
                v-model="search.system"
                v-validate="'required'"
                name="系統"
                class="form-control search_brand"
                :class="{'is-invalid': errors.has('search.系統')}"
                data-vv-scope="search"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  系統
                </option>
                <option
                  v-for="item in system"
                  :key="item.systemCode"
                  :value="item.systemCode"
                >
                  {{ item.systemName }}
                </option>
              </select>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="category">分類</label>
              <select
                id="category"
                v-model="search.category_code"
                v-validate="'required'"
                name="分類"
                class="form-control search_brand"
                :class="{'is-invalid': errors.has('search.分類')}"
                data-vv-scope="search"
                @change="get_news_list()"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  分類
                </option>
                <option
                  v-for="item in filter_news_category"
                  :key="item.categoryCode"
                  :value="item.categoryCode"
                >
                  {{ item.categoryName }}
                </option>
              </select>
            </div>
            <a
              v-if="!edit_mode"
              href="#"
              class="search_btn d-flex align-items-center justify-content-center rounded px-2"
              @click.prevent="init_news(), init_validate(), edit_mode = true, edit_section_new = true"
            >
              <span>建立消息</span>
            </a>
            <a
              v-else
              href="#"
              class="search_btn d-flex align-items-center justify-content-center ml-auto rounded"
              @click.prevent="init_news(), init_validate()"
            >
              <span>返回</span>
            </a>
          </div>
        </div>
        <div class="table-responsive">
          <table
            v-if="!edit_mode"
            class="table table-borderless"
          >
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  圖片
                </th>
                <th scope="col">
                  消息代碼
                </th>
                <th scope="col">
                  消息標題
                </th>
                <th scope="col">
                  起始/結束
                </th>
                <th scope="col">
                  置頂
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(newsitem) in filter_Data"
                :key="newsitem.newsSerial"
              >
                <td>
                  <div
                    v-if="search.system === 'GP'"
                    class="image_box"
                  >
                    <img
                      :src="newsitem.images.imageListGping + '?' + `${Math.random()}`"
                      class="img-fluid"
                      alt=""
                    >
                  </div>
                  <div
                    v-if="search.system === 'WE'"
                    class="image_box"
                  >
                    <img
                      :src="newsitem.images.imageListWuer + '?' + `${Math.random()}`"
                      class="img-fluid"
                      alt=""
                    >
                  </div>
                  <div
                    v-if="search.system === 'JH'"
                    class="image_box"
                  >
                    <img
                      :src="newsitem.images.imageListFugui + '?' + `${Math.random()}`"
                      class="img-fluid"
                      alt=""
                    >
                  </div>
                  <div
                    v-if="search.system === 'GH'"
                    class="image_box"
                  >
                    <img
                      :src="newsitem.images.imageListHealth + '?' + `${Math.random()}`"
                      class="img-fluid"
                      alt=""
                    >
                  </div>
                </td>
                <td class="">
                  <a
                    href="#"
                    class="d-block text-color_primary"
                    @click.prevent="get_news_detail(newsitem.newsSerial)"
                  >
                    {{ newsitem.newsSerial }}
                  </a>
                  <a
                    v-if="!newsitem.status"
                    href="#"
                    class="d-inline-block btn btn_outline_danger rounded-sm px-2"
                    @click.prevent="delete_news(newsitem.newsSerial)"
                  >刪除</a>
                </td>
                <td class="">
                  {{ newsitem.title }}
                </td>
                <td class="">
                  <div>{{ newsitem.dtPublic }}</div>
                  <div>{{ newsitem.dtUnPublic }}</div>
                </td>
                <td class="">
                  <div>{{ newsitem.optionTop === false ? '否' : '是' }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ul
          v-if="filter_Data.length > 0 && !edit_mode"
          class="pagination"
        >
          <li
            class="pagination-item"
            :class="{ 'pagination-item-disabled': !pagination.has_pre }"
          >
            <a
              href="#"
              class="pagination-link"
              aria-label="Previous"
              @click.prevent="change_page(pagination.current_page - 1)"
            >
              <i class="icon-chevron-thin-left" />
            </a>
          </li>
          <li
            v-for="(page, index) in pagination.filter_range_page"
            :key="index"
            class="pagination-item"
          >
            <a
              href="#"
              class="pagination-link"
              :class="{ 'pagination-link-active': pagination.current_page === page.index }"
              @click.prevent="change_page(page.index)"
            >{{ page.index }}</a>
          </li>
          <li
            v-if="pagination.total_pages > 10"
            class="pagination-item"
          >
            <span>...</span>
          </li>
          <li
            class="pagination-item"
            :class="{ 'pagination-item-disabled': !pagination.has_next }"
          >
            <a
              href="#"
              class="pagination-link"
              aria-label="Next"
              @click.prevent="change_page(pagination.current_page + 1)"
            >
              <i class="icon-chevron-thin-right" />
            </a>
          </li>
        </ul>
        <overlay-scrollbars
          v-show="edit_mode"
          class="edit_section"
          :options="{ scrollbars: { autoHide: 'scroll' } }"
        >
          <div class="section_header">
            <span v-show="edit_section_new">建立消息</span>
            <span v-show="!edit_section_new">編輯消息</span>
          </div>
          <div
            class="section_body"
          >
            <div
              v-if="temp_search.length !== 0 && temp_category_data.length === temp_search.length"
              class="mb-3"
            >
              <div
                v-for="(item, index) in temp_category_data"
                :key="index"
                class="search_section d-flex align-items-center"
              >
                <div class="form-group d-flex align-items-center">
                  <label :for="`temp_search_system_${index}`">系統</label>
                  <select
                    :id="`temp_search_system_${index}`"
                    v-model="temp_search[index].system"
                    v-validate="'required'"
                    :name="`系統 ${index + 1}`"
                    class="form-control search_brand"
                    :class="{'is-invalid': errors.has(`detail.系統 ${index + 1}`)}"
                    data-vv-scope="detail"
                    @change="get_temp_category($event.target.value, index)"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      系統
                    </option>
                    <option
                      v-for="system in system"
                      :key="system.systemCode"
                      :value="system.systemCode"
                    >
                      {{ system.systemName }}
                    </option>
                  </select>
                </div>
                <div class="form-group d-flex align-items-center">
                  <label :for="`temp_search_categoryCode_${index}`">分類</label>
                  <select
                    :id="`temp_search_categoryCode_${index}`"
                    v-model="temp_search[index].categoryCode"
                    v-validate="'required'"
                    :name="`分類 ${index + 1}`"
                    class="form-control"
                    :class="{'is-invalid': errors.has(`detail.分類 ${index + 1}`)}"
                    data-vv-scope="detail"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      分類
                    </option>
                    <option
                      v-for="category in item.category"
                      :key="category.categoryCode"
                      :value="category.categoryCode"
                    >
                      {{ category.categoryName }}
                    </option>
                  </select>
                </div>
                <a
                  v-if="temp_category_data.length > 1"
                  href="#"
                  class="circle_btn"
                  @click.prevent="delete_category(index)"
                >
                  <i class="icon-minus" />
                </a>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <a
                  href="#"
                  class="d-flex btn btn_primary px-2"
                  @click.prevent="add_category()"
                >
                  新增歸屬
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label>消息狀態</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="news_status_true"
                      v-model="news.status"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="消息狀態"
                      :value="true"
                    >
                    <label
                      class="form-check-label"
                      for="news_status_true"
                    >是</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="new_status_false"
                      v-model="news.status"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="消息狀態"
                      :value="false"
                    >
                    <label
                      class="form-check-label"
                      for="new_status_false"
                    >否</label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label>置頂</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="news_optionTop_true"
                      v-model="news.optionTop"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="置頂"
                      :value="true"
                    >
                    <label
                      class="form-check-label"
                      for="news_optionTop_true"
                    >是</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="new_optionTop_false"
                      v-model="news.optionTop"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="置頂"
                      :value="false"
                    >
                    <label
                      class="form-check-label"
                      for="new_optionTop_false"
                    >否</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="news_title">標題</label>
              <input
                id="news_title"
                v-model="news.title"
                v-validate="'required'"
                type="text"
                name="標題"
                class="form-control form_input flex-fill"
                :class="{'is-invalid': errors.has('detail.標題')}"
                data-vv-scope="detail"
                placeholder="標題"
              >
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="news_link">連結</label>
              <input
                id="news_link"
                v-model="news.urlPath"
                type="text"
                name="連結"
                class="form-control form_input flex-fill"
                placeholder="連結"
              >
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="news_htmlTitle">網頁標題</label>
              <input
                id="news_htmlTitle"
                v-model="news.htmlTitle"
                v-validate="'required'"
                type="text"
                name="網頁標題"
                class="form-control form_input flex-fill"
                :class="{'is-invalid': errors.has('detail.網頁標題')}"
                data-vv-scope="detail"
                placeholder="網頁標題"
              >
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="news_htmlKeywords">網頁關鍵字</label>
              <input
                id="news_htmlKeywords"
                v-model="news.htmlKeywords"
                v-validate="'required'"
                type="text"
                name="網頁關鍵字"
                class="form-control form_input flex-fill"
                :class="{'is-invalid': errors.has('detail.網頁關鍵字')}"
                data-vv-scope="detail"
                placeholder="網頁關鍵字"
              >
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="news_htmlDesc">網頁描述</label>
              <input
                id="news_htmlDesc"
                v-model="news.htmlDesc"
                v-validate="'required'"
                type="text"
                name="網頁描述"
                class="form-control form_input flex-fill"
                :class="{'is-invalid': errors.has('detail.網頁描述')}"
                data-vv-scope="detail"
                placeholder="網頁描述"
              >
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="news_dtPublic">起始日期</label>
                  <input
                    id="news_dtPublic"
                    v-model="news.dtPublic"
                    v-validate="'required'"
                    type="date"
                    name="起始日期"
                    class="form-control form_input flex-fill"
                    :class="{'is-invalid': errors.has('detail.起始日期')}"
                    data-vv-scope="detail"
                    placeholder="起始日期"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="news_dtUnPublic">結束日期</label>
                  <input
                    id="news_dtUnPublic"
                    v-model="news.dtUnPublic"
                    v-validate="'required'"
                    type="date"
                    name="結束日期"
                    class="form-control form_input flex-fill"
                    :class="{'is-invalid': errors.has('detail.結束日期')}"
                    data-vv-scope="detail"
                    placeholder="結束日期"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="news_dtUpdate">更新日期</label>
                  <input
                    id="news_dtUpdate"
                    v-model="news.dtUpdate"
                    type="date"
                    name="更新日期"
                    disabled
                    class="form-control form_input flex-fill"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="news_dtCreate">建立日期</label>
                  <input
                    id="news_dtCreate"
                    v-model="news.dtCreate"
                    type="date"
                    name="建立日期"
                    disabled
                    class="form-control form_input flex-fill"
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="text-left mb-2">摘要</label>
              <ckeditor
                v-model="news.summary"
                :editor="editor"
                :config="editorConfig"
              />
            </div>
            <div class="form-group">
              <label class="text-left mb-2">內文</label>
              <ckeditor
                v-model="news.content"
                :editor="editor"
                :config="editorConfig"
                @ready="upload_article_image"
              />
            </div>
            <div
              v-if="edit_mode"
              class="row justify-content-between"
            >
              <div class="col form-group form_input_image">
                <label for="">吉品列表<br>(435x275)</label>
                <div class="input_custom">
                  <span>加入圖片</span>
                </div>
                <input
                  ref="files"
                  type="file"
                  data-name="imageListGping"
                  class="form-control"
                  accept="image/jpeg"
                  @change="readURL($event,$event.target)"
                >
                <div class="display_image">
                  <!-- <img id="imageListGping" :src="news.images.imageListGping + '?' + `${Math.random()}`" class="img-fluid" /> -->
                  <img
                    id="imageListGping"
                    :src="news_images.imageListGping"
                    class="img-fluid"
                  >
                </div>
              </div>
              <!-- <div class="col">
                <div class="form-group form_input_image">
                  <label for="">吉品內文(435x275)</label>
                  <div class="input_custom">
                    <span>加入圖片</span>
                  </div>
                  <input
                    type="file"
                    data-name="imageArticleGping"
                    class="form-control"
                    @change="readURL($event,$event.target)"
                    ref="files"
                    accept="image/jpeg"
                  />
                  <div class="display_image">
                    <img id="imageArticleGping" :src="news.images.imageArticleGping + '?' + `${Math.random()}`" class="img-fluid" />
                    <img id="imageArticleGping" :src="news_images.imageArticleGping" class="img-fluid" />
                  </div>
                </div>
              </div> -->
              <div class="col form-group form_input_image">
                <label for="">無二列表<br>(1400x890)</label>
                <div class="input_custom">
                  <span>加入圖片</span>
                </div>
                <input
                  ref="files"
                  type="file"
                  data-name="imageListWuer"
                  class="form-control"
                  accept="image/jpeg"
                  @change="readURL($event,$event.target)"
                >
                <div class="display_image">
                  <!-- <img id="imageListWuer" :src="news.images.imageListWuer + '?' + `${Math.random()}`" class="img-fluid" /> -->
                  <img
                    id="imageListWuer"
                    :src="news_images.imageListWuer"
                    class="img-fluid"
                  >
                </div>
              </div>
              <div class="col form-group form_input_image">
                <label for="">福貴糕列表<br>(480x320)</label>
                <div class="input_custom">
                  <span>加入圖片</span>
                </div>
                <input
                  ref="files"
                  type="file"
                  data-name="imageListFugui"
                  class="form-control"
                  accept="image/jpeg"
                  @change="readURL($event,$event.target)"
                >
                <div class="display_image">
                  <!-- <img id="imageListFugui" :src="news.images.imageListFugui + '?' + `${Math.random()}`" class="img-fluid" /> -->
                  <img
                    id="imageListFugui"
                    :src="news_images.imageListFugui"
                    class="img-fluid"
                  >
                </div>
              </div>
              <div class="col form-group form_input_image">
                <label for="">福貴糕媒體<br>(480x320)</label>
                <div class="input_custom">
                  <span>加入圖片</span>
                </div>
                <input
                  ref="files"
                  type="file"
                  data-name="imageMediaFugui"
                  class="form-control"
                  accept="image/jpeg"
                  @change="readURL($event,$event.target)"
                >
                <div class="display_image">
                  <!-- <img id="imageMediaFugui" :src="news.images.imageMediaFugui + '?' + `${Math.random()}`" class="img-fluid" /> -->
                  <img
                    id="imageMediaFugui"
                    :src="news_images.imageMediaFugui"
                    class="img-fluid"
                  >
                </div>
              </div>
              <div class="col form-group form_input_image">
                <label for="">保健V2列表<br>(未確認)</label>
                <div class="input_custom">
                  <span>加入圖片</span>
                </div>
                <input
                  ref="files"
                  type="file"
                  data-name="imageListHealth"
                  class="form-control"
                  accept="image/jpeg"
                  @change="readURL($event,$event.target)"
                >
                <div class="display_image">
                  <!-- <img id="imageListHealth" :src="news.images.imageListHealth + '?' + `${Math.random()}`" class="img-fluid" /> -->
                  <img
                    id="imageListHealth"
                    :src="news_images.imageListHealth"
                    class="img-fluid"
                  >
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <a
                href="#"
                class="btn btn_primary rounded-0"
                @click.prevent="edit_news(edit_section_new)"
              >{{ edit_section_new === true ? '建立' : '更新' }}</a>
              <a
                href="#"
                class="btn btn_default rounded-0"
                @click.prevent="edit_mode = false, edit_section_new = false"
              >關閉</a>
            </div>
          </div>
        </overlay-scrollbars>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_NEWS_CATEGORY,
  API_GET_NEWS_LIST,
  API_GET_NEWS_DETAIL,
  API_UPDATE_NEWS,
  API_CREATE_NEWS,
  API_DELETE_NEWS,
  API_UPLOAD_NEWSIMAGE,
} from '@/plugins/api';
import { ckeditor, MyUploadAdapter } from '@/plugins/ckeditor';

export default {
  data() {
    return {
      now_page: 'news',
      news_category: [], /*  最新消息分類 */
      news_list: [], /* 最新消息列表 */
      search: {
        system: '',
        category_code: '',
      },
      news: {
        images: {},
      }, /* 最新消息細節 */
      edit_mode: false, /* 編輯模式 */
      edit_section_new: false, /* 編輯的區塊是否為新 */
      editor: ckeditor.editor,
      editorConfig: ckeditor.editorConfig,
      temp_search: [
        /* 建立、 編輯用 */
        {
          system: '',
          categoryCode: '',
        },
      ],
      temp_category_data: [
        {
          category: [],
        },
      ],
      pagination: {
        /* 分頁設定 */
        filter_page_size: 20,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      },
    };
  },
  computed: {
    ...mapGetters('system', ['system']),
    filter_news_category() {
      const vm = this;
      vm.search.category_code = '';
      return vm.news_category.filter((item) => item.systemCode === vm.search.system);
    },
    filter_Data() {
      const vm = this;
      const dataLen = vm.news_list.length;
      const nowPage = vm.pagination.current_page;
      vm.pagination.total_pages = Math.ceil(
        dataLen / vm.pagination.filter_page_size,
      );
      vm.pagination.filter_range_page = [];
      for (let index = 1; index <= vm.pagination.total_pages; index += 1) {
        if (index >= (nowPage - vm.pagination.filter_range) && index <= (nowPage + vm.pagination.filter_range)) {
          vm.pagination.filter_range_page.push({
            index,
          });
        }
      }
      if (nowPage > 1) {
        vm.pagination.has_pre = true;
      } else {
        vm.pagination.has_pre = false;
      }
      if (nowPage === vm.pagination.total_pages) {
        vm.pagination.has_next = false;
      } else {
        vm.pagination.has_next = true;
      }
      return vm.news_list.filter(
        (item, index) => index < nowPage * vm.pagination.filter_page_size
          && index >= (nowPage - 1) * vm.pagination.filter_page_size,
      );
    },
    news_images() {
      const vm = this;
      let data = {
        imageListFugui: `${process.env.VUE_APP_API_PATH}/images/news/imageListFugui/fuguilist.jpg`,
        imageListGping: `${process.env.VUE_APP_API_PATH}/images/news/imageListGping/gpinglist.jpg`,
        imageListWuer: `${process.env.VUE_APP_API_PATH}/images/news/imageListWuer/wuerlist.jpg`,
        imageListHealth: `${process.env.VUE_APP_API_PATH}/images/news/imageListHealth/healthlist.jpg`,
        imageMediaFugui: `${process.env.VUE_APP_API_PATH}/images/news/imageMediaFugui/fuguimedia.jpg`,
      };
      const newsLength = Object.keys(vm.news.images).length;
      if (newsLength !== 0) {
        data = Object.assign(vm.news.images);
      }
      return data;
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_news_category();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system']),
    /* 初始化 news 內容 */
    async init_news() {
      const vm = this;
      vm.news = {
        images: {},
      };
      vm.edit_mode = false;
      vm.edit_section_new = false;
      vm.editor = ckeditor.editor;
      vm.editorConfig = ckeditor.editorConfig;
      vm.temp_search = [
        {
          system: '',
          categoryCode: '',
        },
      ];
      vm.temp_category_data = [
        {
          category: [],
        },
      ];
    },
    /* 初始化驗證 */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('search');
        vm.$validator.errors.clear('detail');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    async init_pagination() {
      const vm = this;
      vm.pagination = {
        filter_page_size: 20,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      };
    },
    /* 切換頁數 */
    change_page(page = 1) {
      const vm = this;
      vm.pagination.current_page = page;
    },
    /* 取得最新消息分類 */
    async get_news_category() {
      const vm = this;
      const response = await API_GET_NEWS_CATEGORY();
      const res = response.data;
      if (res.code === '0000') {
        vm.news_category = res.data;
      }
    },
    /* 取得最新消息列表 */
    async get_news_list() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      if (vm.search.category_code === '') {
        await vm.$store.dispatch('page_load', false);
        return;
      }
      const response = await API_GET_NEWS_LIST(vm.search.category_code);
      const res = response.data;
      if (res.code === '0000') {
        vm.news_list = res.data;
        await vm.init_news();
        await vm.init_validate();
        await vm.init_pagination();
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得消息細節 */
    async get_news_detail(newsSerial) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_NEWS_DETAIL(newsSerial);
      const res = response.data;
      if (res.code === '0000') {
        vm.news = res.data;
        vm.edit_mode = true;
        vm.temp_search = [];
        vm.temp_category_data = [];
        for (let index = 0; index < vm.news.category.length; index += 1) {
          const categoryData = {
            category: [],
          };
          vm.temp_category_data.push(categoryData);
          vm.temp_category_data[index].category = vm.news.category;
          const searchData = {
            categoryCode: vm.news.category[index].categoryCode,
            system: vm.news.category[index].systemCode,
          };
          vm.temp_search.push(searchData);
        }
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得 news 分類列表 => 多組分類時專用 */
    async get_temp_category(value, index) {
      const vm = this;
      vm.temp_category_data[index].category = vm.news_category.filter((item) => item.systemCode === vm.temp_search[index].system);
    },
    /* 新增商品分類 */
    async add_category() {
      const vm = this;
      const category = {
        system: '',
        categoryCode: '',
      };
      const categoryData = {
        category: [],
      };
      vm.temp_search.push(category);
      vm.temp_category_data.push(categoryData);
    },
    /* 刪除商品分類 */
    async delete_category(index) {
      const vm = this;
      vm.temp_search.splice(index, 1);
      vm.temp_category_data.splice(index, 1);
    },
    /* 檢測 建立/更新 消息資料 */
    async edit_news(isnew) {
      const vm = this;
      switch (isnew) {
        case true:
          await vm.create_news();
          break;
        case false:
          await vm.update_news();
          break;
        default:
          break;
      }
    },
    /* 建立消息 */
    async create_news() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        const categoryCodelist = [];
        vm.temp_search.forEach((item) => {
          categoryCodelist.push(item.categoryCode);
        });
        const data = {
          status: vm.news.status,
          optionTop: vm.news.optionTop,
          title: vm.news.title,
          summary: vm.news.summary || '',
          content: vm.news.content || '',
          urlPath: vm.news.urlPath || '',
          htmlTitle: vm.news.htmlTitle,
          htmlKeywords: vm.news.htmlKeywords,
          htmlDesc: vm.news.htmlDesc,
          dtPublic: vm.news.dtPublic,
          dtUnPublic: vm.news.dtUnPublic,
          category: categoryCodelist,
        };
        const response = await API_CREATE_NEWS(data);
        const res = response.data;
        if (res.code === '0000') {
          await vm.upload_picture(res.data.newsSerial);
          vm.edit_mode = false;
          vm.edit_section_new = false;
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_news_list();
          await vm.init_news();
          await vm.init_validate();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新消息 */
    async update_news() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        const categoryCodelist = [];
        vm.temp_search.forEach((item) => {
          categoryCodelist.push(item.categoryCode);
        });
        const data = {
          status: vm.news.status,
          optionTop: vm.news.optionTop,
          newsSerial: vm.news.newsSerial,
          title: vm.news.title,
          summary: vm.news.summary || '',
          content: vm.news.content || '',
          urlPath: vm.news.urlPath || '',
          htmlTitle: vm.news.htmlTitle,
          htmlKeywords: vm.news.htmlKeywords,
          htmlDesc: vm.news.htmlDesc,
          dtPublic: vm.news.dtPublic,
          dtUnPublic: vm.news.dtUnPublic,
          category: categoryCodelist,
        };
        const response = await API_UPDATE_NEWS(data);
        const res = response.data;
        if (res.code === '0000') {
          await vm.upload_picture(res.data.newsSerial);
          vm.edit_mode = false;
          vm.edit_section_new = false;
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_news_list();
          await vm.init_news();
          await vm.init_validate();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 刪除消息 */
    async delete_news(newsSerial) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_DELETE_NEWS(newsSerial);
      const res = response.data;
      if (res.code === '0000') {
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          timer: 1000,
          confirmButtonText: '關閉視窗',
        });
        await vm.get_news_list();
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 預覽圖片 */
    async readURL(e, input) {
      const targetId = e.target.files[0].name;
      if (input.files.length > 0) {
        input.files.forEach((item) => {
          const reader = new FileReader();
          reader.onload = function onload(eDetail) {
            const img = $(e.target).siblings('.display_image').find('img');
            $(img).attr('src', eDetail.target.result);
            // $(e.target).siblings('.input_custom').find('span').text(targetId);
          };
          reader.readAsDataURL(item);
        });
      }
    },
    /* ckeditor 上傳圖片 */
    upload_article_image(editor) {
      const vm = this;
      // 自定義上傳圖片插件
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new MyUploadAdapter(vm.news.newsSerial, loader);
    },
    /* 上傳消息圖片 */
    async upload_picture(newsSerial) {
      const vm = this;
      const productImageData = Array.from(
        document.querySelectorAll('.form_input_image input'),
      );
      const data = new FormData();
      data.append('newsSerial', newsSerial);
      productImageData.forEach((image) => {
        data.append(image.dataset.name, image.files[0]);
      });
      await API_UPLOAD_NEWSIMAGE(data);
    },
  },
};
</script>
